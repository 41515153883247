import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import TrustedBy from "../components/hireVa/trusted"
import SaveTime from "../components/hireVa/savetime"
import GoodChoice from '../components/hireVa/goodchoice'
import PostingRole from '../components/hireVa/postingrole'
import Assistant from '../components/hireVa/assistant'
import Booking from '../components/hireVa/booking'

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import GetInTouch from  '../components/hireVa/getintouch'
import { useStaticQuery, graphql, Link } from "gatsby"



const EcommerceService = ({ data }) => { 

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow:false
      };
      const banner_title  = ['Fully Managed', 3000, 'Fully Trained ', 2000];
      const banner_button = 'Hire Vetted Virtual Assistant';

return (

    <Layout>
<Seo title="Find the best freelance Virtual Assistants as per Demand  " />


<Banner title = {banner_title} sticky_first = "Hire" sticky_second  = "Ecommerce Specialists" button = {banner_button} />
<section className = "inro" >
<div className = "container">
  <p>All of our ecommerce va are trained in exclusive in house boot camps </p>
</div>
</section>


{/* 
        <div className = "vr_banner inner">
        <StaticImage
                            src="../images/e-commerce-banner.png"
                            quality = "90"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Let Ecommerce specialists handle your web channels </h1>
            <p>
            Delegate all your Web Challenges to Ecommerce VA. Our global developers are flexible and trained for all kinds of Complex Work.   
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">Hire Ecommerce VA  </Link>
        </div>
        </div>


        </div>
*/}


<section className = "trained serv_det">

    <div className="container">
        
        <ul>

        <li>
        <StaticImage
            src="../images/Magento-ser.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Magento</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/Shopify-ser.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Shopify</h4>

        </li>



        <li>
        <StaticImage
            src="../images/Woo.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>WooCommerce</h4>

        </li>



        <li>
        <StaticImage
            src="../images/amazon-ec.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Amazon</h4>

        </li>



        <li>
        <StaticImage
            src="../images/walmart.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Walmart</h4>

        </li>

  
        <li>
        <StaticImage
            src="../images/etsy-marketplace.png"
            quality = "100"
            width = "140"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Etsy Marketplace</h4>

        </li>

    

    </ul>

    </div>


</section>

<TrustedBy/>


<SaveTime/>




<GoodChoice/>

<section className = "trained serv_det">

    <div className="container">

      <h1>WHAT CAN AN ECOMMERCE VIRTUAL ASSISTANT DO?</h1>
      <p>An eCommerce virtual assistant provides remote assistance to eCommerce business 
          owners to help them run their online stores successfully and scale up their operations. You 
          can delegate many tasks to an e-commerce VA, especially those that do not directly require your involvement.
           The task delegation also depends on the type of business or entrepreneurs. The basics of handling an e-commerce 
           store involve order processing. It is a recurring task that happens daily, weekly or monthly, depending upon 
           your business requirements. An e-commerce assistant is ideal
           for managing orders, shipments, and releasing invoices.</p>


<div className = "row">

        <ul>

        <li>
        <StaticImage
            src="../images/customer-satisfaction.png"
            quality = "100"
          width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Customer Satisfaction</h4>

        </li>


        
        <li>
        <StaticImage
            src="../images/handle-shipping.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Handle Shipping</h4>

        </li>



        <li>
        <StaticImage
            src="../images/manage-promotions.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Manage Promotions</h4>

        </li>



        <li>
        <StaticImage
            src="../images/manage-returns.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Manage Returns & Exchanges</h4>

        </li>



        <li>
        <StaticImage
            src="../images/online-products-selling.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Online Products Selling</h4>

        </li>
        <li>
        <StaticImage
            src="../images/pricing-information.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Pricing Information</h4>

        </li>

        <li>
        <StaticImage
            src="../images/process-orders.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Process Orders</h4>

        </li>
        <li>
        <StaticImage
            src="../images/finances-caring.png"
            quality = "100"
            width = "80"
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
            />
            <h4>Finance Caring</h4>

        </li>
    </ul>
    </div>


    </div>


</section>


<section className = "service_package">
<h1>CHOOSE YOUR PLAN</h1>
<div className = "container">

   

<div class="naccs">
  <div class="grid">
   <div class="gc gc--1-of-3">
    <div class="menu">
     <div class="active"><span class="light"></span><span>Part Time VA</span></div>
     <div><span class="light"></span><span>Dedicated VA</span></div>
   
    </div>
   </div>
   <div class="gc gc--2-of-3">
    <ul class="nacc">
     <li class="active">
      <div className = "inner_data">

        <div className = "list">
        <h2>Part Time VA</h2>
          <span><i class="fa fa-check"></i> Account Management</span>
          <span><i class="fa fa-check"></i> Product research</span>
          <span><i class="fa fa-check"></i> Product description writing  </span>
          <span><i class="fa fa-check"></i> Image editing</span>
          <span><i class="fa fa-times"></i> Product listing and optimisation</span>
          <span><i class="fa fa-check"></i> Managing inventory</span>
          <span><i class="fa fa-times"></i> Handling order processing</span>
          <span><i class="fa fa-times"></i> Assistance in returns and exchanges</span>
         
        </div>

        <div className = "start_now">
          <h2>$20</h2>
          <span>Per Hour</span>
          <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">start now</Link>
        </div>
       
     
      </div>
     </li>
     <li>
     <div className = "inner_data">

<div className = "list">
<h2>Dedicated VA</h2>
<span><i class="fa fa-check"></i> Account Management</span>
          <span><i class="fa fa-check"></i> Product research</span>
          <span><i class="fa fa-check"></i> Product description writing  </span>
          <span><i class="fa fa-check"></i> Image editing</span>
          <span><i class="fa fa-check"></i> Product listing and optimisation</span>
          <span><i class="fa fa-check"></i> Managing inventory</span>
          <span><i class="fa fa-check"></i> Handling order processing</span>
          <span><i class="fa fa-check"></i> Assistance in returns and exchanges</span>
</div>

<div className = "start_now">
  <h2>$17</h2>
  <span>Per Hour</span>
  <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discoverycall">start now</Link>
</div>


</div>


     </li>
 
    </ul>
   </div>
  </div>
 </div>

</div>


</section>


<PostingRole/>

<Assistant/>




<Booking/>



<Testimonials/>
<Faqs/>
<GetInTouch/>



 </Layout>

)


}

export default EcommerceService;